import React from 'react';
import '../styles/hero.scss';
import FistLogo from '../assets/logos/fist.svg';
import SpeechBubble from '../assets/icons/icon-speech.svg';
import Arrow from '../assets/icons/icon-arrow.svg';

const Hero = () => {

    const scrollToForm = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }

    const scrollToCaseStudies = () => {
        document.getElementById('case-studies').scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <section className="hero">
            <div className="content">
                <div className="logo">
                    <FistLogo />
                </div>
                <h2 className="ff heading">
                    A boutique ecommerce agency for growing businesses
                </h2>
                <p className="ff">
                    If you’re looking for a competition-beating conversion rate, whilst partnering with a commercially-minded and ambitious team,<br /> then you’ve come to the right place.
                </p>
            </div>
            <div className="buttons">
                <button className="ff ff-secondary" onClick={scrollToForm}>
                    <SpeechBubble />
                    <span>Get in touch</span>
                </button>
                <button className="ff ff-primary" onClick={scrollToCaseStudies}>
                    <span>Browse case studies</span>
                    <Arrow />
                </button>
            </div>
        </section>
    )

}

export default Hero