import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../styles/testimonials.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

const Testimonials = ({ testimonials }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const timeoutRef = useRef(null);
    const interval = 5000;
    const options = [{
        breakpoint: 500,
        slidesToShow: 2
    }, {
        breakpoint: 0,
        slidesToShow: 1,
    }];
    const [currentOption, setCurrentOption] = useState(options[0]);   
    
    const setupCarousel = () => {
        if (currentOption.slidesToShow >= testimonials.length) return
        resetTimeout();
        timeoutRef.current = setTimeout(() => handleNext(), interval);
        return () => {
            resetTimeout();
        };
    }
    
    useEffect(() => {
        if (typeof window === 'undefined') return;
        setCurrentOption(options.find(option => window.innerWidth > option.breakpoint));
        setupCarousel();

        window.addEventListener('resize', () => {
            setCurrentOption(options.find(option => window.innerWidth > option.breakpoint));
            setupCarousel();
        });

        return () => {
            setupCarousel();
        };
    }, [currentIndex, testimonials.length, interval]);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const handleNext = () => {
        if (currentOption.slidesToShow >= testimonials.length) return
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
      };
    
    const handlePrev = () => {
        if (currentOption.slidesToShow >= testimonials.length) return
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <section className='ff testimonials'>
            <h2 className='ff ff-section-header'>Testimonials</h2>
            <div className="carousel" {...handlers}>
                <div className="carousel-track" style={{ transform: `translateX(${-currentIndex * 100}vw)` }}>
                    {testimonials.map((testimonial, index) => {
                        return (
                            <div key={index} className={`testimonial carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                                <p className="ff">{testimonial.text}</p>
                                <div className="client">
                                    <div className="client-image">
                                        <GatsbyImage image={testimonial.client.image} alt={`${testimonial.client.name} profile`} />
                                    </div>
                                    <span className="ff-bold client-name">{testimonial.client.name}</span>
                                    <div>
                                        <span className="ff-bold company">{testimonial.client.company}</span><span className="ff slash">/</span><span className="ff client-position">{testimonial.client.position}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='dots'>
                    {testimonials.map((_, index) => {
                        return (
                            <div key={index} className={`dot ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)}></div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Testimonials;