import React from "react";
import '../styles/logos.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import Bedfolk from '../assets/clients/logos/bedfolk-logo.svg';
import Coat from '../assets/clients/logos/coat-logo.svg';
import Jubel from '../assets/clients/logos/jubel-logo.svg';
import Cambridge from '../assets/clients/logos/cambridge-satchel-co-logo.svg';
import HouseOf from '../assets/clients/logos/house-of-logo.svg';
import Seep from '../assets/clients/logos/seep-logo.svg';
import BeyondNine from '../assets/clients/logos/beyond-nine-logo.svg';
import EdgeOfEmber from '../assets/clients/logos/edge-of-ember-logo.svg';
import Loftie from '../assets/clients/logos/loftie-logo.svg';
import PlankHardware from '../assets/clients/logos/plank-hardware-logo.svg';
import RussellAndAtwell from '../assets/clients/logos/russell-and-atwell-logo.svg';
import SimplyRoasted from '../assets/clients/logos/simply-roasted-logo.svg';
import StoriesAndInk from '../assets/clients/logos/stories-and-ink-logo.svg';
import Tala from '../assets/clients/logos/tala-logo.svg';
import Underdays from '../assets/clients/logos/underdays-logo.svg';

const Logos = () => {
    return (
        <section className="ff logos">
            <h2 className="ff ff-section-header">Trusted by</h2>
            <div className="ff grid">
                <Coat />
                <Tala />
                <Cambridge />
                <EdgeOfEmber className="reduce-md" />
                <Bedfolk />
                <Loftie className="reduce-sm"/>
                <Underdays className="reduce-lg" />
                <BeyondNine />
                <Jubel className="reduce-md" />
                <HouseOf />
                <SimplyRoasted className="reduce-sm" />
                <RussellAndAtwell className="reduce-sm" />
                <PlankHardware className="reduce-sm" />
                <Seep className="reduce-lg" />
                <StoriesAndInk />
            </div>
        </section>
    )
}

export default Logos